(function() {

  /*
   * HEY. domains hash exists in :
   * site/js/lang_toggle.js
   * site/js/lang_redirect.js
   *
   * THEY MUST STAY THE SAME. chnage one, change the other.
   */

  var domains = {
    // France
    'fr_FR' : '',
    'en_FR' : '/en-e-fr',
    // SA
    en_SA: '',
    ar_SA: '/ar-e-sa',
    // AE
    en_AE: '',
    ar_AE: '/ar-e-ae'
  };

  var cookie_tools = {

    set: function (name, value, days) {
      var expires = "";
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + value + expires + "; path=/";
    },

    get: function(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(';');
      for(var i=0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
      }
      return null;
    },

    remove: function(name) {
      this.set(name, "", -1);
    },
  }

  function check_locale_prefix(domains) {
    var path = window.location.pathname;
    var pathArray = path.split('/');
    var locale_cookie = cookie_tools.get('LOCALE');

    // no cookie bail
    if (!locale_cookie) {
      return;
    }

    // skip tmpl
    if(path.indexOf('.tmpl') !== -1) {
      return;
    }

    var domainPath = domains[locale_cookie];
    if (!domainPath) {
      // no domain path for this locale
      return;
    }

    var destination_url = window.location.protocol + '//' + window.location.host + domainPath + window.location.pathname + window.location.search + window.location.hash;

    if (domainPath.substring(1).toLowerCase() === pathArray[1].toLowerCase()) {
      // prefix already set correctly
      return;
    }


    // good to redirect
    window.location.href = destination_url;
  }

  check_locale_prefix(domains);
})();
